import { Route, Routes } from "react-router";

import Login from "features/authentication/login.page";
import Home from "features/home/home.page";

export default function RootContainer() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
    </Routes>
  );
}
