import AppIcon from "./app";
import { motion } from "framer-motion";

export default function Tab({ apps = [] }) {
  return (
    <motion.div
      className="flex justify-between items-center backdrop-blur w-[90vw] bg-white/20 fixed bottom-5 left-0 right-0 m-auto rounded-3xl px-5 py-3"
      animate={{
        y: [100, 0],
        opacity: [0, 1],
      }}
      transition={{ duration: 0.5, ease: "easeInOut" }}
    >
      {apps.map((app) => (
        <AppIcon key={app.name} {...app} showName={false} />
      ))}
    </motion.div>
  );
}
