import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

export default function AppIcon({
  name = "Name",
  icon = "Settings.png",
  path = "/",
  showName = true,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const handleClick = () => {
    setIsOpen(true);
    setTimeout(() => {
      if (path.startsWith("http")) {
        window.location.href = path;
        setIsOpen(false);
      } else {
        navigate(path);
        setIsOpen(false);
      }
    }, 700);
  };

  useEffect(() => {
    return () => {
      setIsOpen(false);
    };
  }, []);

  return (
    <div className="relative w-full">
      {isOpen && (
        <motion.div
          className="rounded-3xl w-[70px] h-[70px] bg-white absolute"
          animate={{
            scale: 100,
            zIndex: 9999,
          }}
          transition={{ duration: 0.7 }}
        />
      )}
      <button
        className="flex flex-col justify-center items-center gap-1 group w-full "
        onClick={handleClick}
      >
        <img
          src={`/assets/home/app/${icon}`}
          alt={name}
          className="rounded-3xl w-[70px] h-[70px] group-active:brightness-50 shadow"
        />
        {showName && (
          <p className="font-montserrat text-center text-xs text-white drop-shadow truncate w-full">
            {name}
          </p>
        )}
      </button>
    </div>
  );
}
