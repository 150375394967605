import { useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/fr";

export default function Clock() {
  const [time, setTime] = useState(moment().format("LT"));
  moment.locale("fr");

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(moment().format("LT"));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="h-[200px] flex justify-center items-center animate__animated animate__fadeIn">
      <div>
        <p className="text-center text-[70px] leading-[70px] font-montserrat font-bold text-white">
          {time}
        </p>
        <p className="text-center text-lg font-montserrat font-bold text-white">
          {moment().format("dddd Do MMMM YYYY")}
        </p>
      </div>
    </div>
  );
}
