import { Page } from "konsta/react";
import moment from "moment";
import { useEffect, useState } from "react";
import Clock from "./_components/clock";
import Tab from "./_components/tab";
import apps from "utils/apps";
import AppIcon from "./_components/app";

export default function Home() {
  return (
    <Page
      style={{ backgroundImage: "url(/assets/home/background.jpg)" }}
      className="bg-center bg-cover h-screen overflow-hidden"
    >
      <Clock />
      <div className="grid grid-cols-4 gap-4 px-6">
        {apps.main.map((app) => (
          <AppIcon key={app.name} {...app} />
        ))}
      </div>
      <Tab apps={apps.tab} />
    </Page>
  );
}
